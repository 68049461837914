import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  tabindex: "-1",
  id: "general_delete_modal",
  ref: "createAPIKeyModalRef",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = { class: "modal-header bg-primary" }
const _hoisted_5 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal",
  id: "deletedevicemodal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body p-10" }
const _hoisted_8 = { class: "fs-4" }
const _hoisted_9 = { class: "fw-lighter text-primary" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "modal-footer mb-5 justify-content-center" }
const _hoisted_13 = {
  key: 0,
  class: "spinner-border spinner-border-sm align-middle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "modal-title h5 text-white ms-3" }, "Delete Device", -1 /* HOISTED */)),
          _createCommentVNode(" close modal icon "),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ]),
          _createCommentVNode(" end close modal icon ")
        ]),
        _createVNode(_component_Form, { class: "form" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_8, [
                  _cache[3] || (_cache[3] = _createTextVNode(" Are You Sure You Want to Delete ")),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.variantId), 1 /* TEXT */),
                  _cache[4] || (_cache[4] = _createTextVNode(" Device ? "))
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("p", { class: "fs-5" }, "Please Enter Your Password To Proceed", -1 /* HOISTED */)),
                _createVNode(_component_Field, {
                  name: "password",
                  type: "password",
                  placeholder: "Enter password",
                  class: "form-control w-50 border border-primary form-control-solid",
                  modelValue: _ctx.user_auth.password,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user_auth.password) = $event))
                }, null, 8 /* PROPS */, ["modelValue"]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_ErrorMessage, { name: "variant_id" })
                  ])
                ])
              ]),
              _createCommentVNode(" <input type=\"password\" /> ")
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[7] || (_cache[7] = _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary",
                "data-bs-dismiss": "modal"
              }, " Cancel ", -1 /* HOISTED */)),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-primary",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
              }, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13))
                  : _createCommentVNode("v-if", true),
                _cache[6] || (_cache[6] = _createTextVNode(" Confirm "))
              ])
            ])
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ], 512 /* NEED_PATCH */))
}