import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "general_view_modal",
  ref: "createAPIKeyModalRef",
  tabindex: "1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header bg-primary",
  id: "kt_modal_create_api_key_header"
}
const _hoisted_5 = {
  class: "text-white h5 text-uppercase",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_6 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_7 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_10 = { class: "d-flex flex-column mb-10 fv-row" }
const _hoisted_11 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_12 = { class: "mb-5 fv-row" }
const _hoisted_13 = {
  key: 2,
  class: "col-md-4"
}
const _hoisted_14 = { class: "mb-5 fv-row" }
const _hoisted_15 = { class: "col-md-4" }
const _hoisted_16 = { class: "mb-5 fv-row" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_19 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_20 = { class: "required fs-5 fw-bold mb-2" }
const _hoisted_21 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_22 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_23 = { class: "col-md-4" }
const _hoisted_24 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_25 = {
  key: 0,
  class: "row"
}
const _hoisted_26 = { class: "col-md-4" }
const _hoisted_27 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_28 = { class: "col-md-4" }
const _hoisted_29 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_30 = { class: "col-md-4" }
const _hoisted_31 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_32 = {
  key: 1,
  class: "row"
}
const _hoisted_33 = { class: "col-md-4" }
const _hoisted_34 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_35 = { class: "col-md-4" }
const _hoisted_36 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_37 = { class: "col-md-4" }
const _hoisted_38 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_39 = {
  key: 2,
  class: "row"
}
const _hoisted_40 = { class: "col-md-4" }
const _hoisted_41 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_42 = { class: "col-md-4" }
const _hoisted_43 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_44 = { class: "col-md-4" }
const _hoisted_45 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_46 = { class: "row" }
const _hoisted_47 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_48 = { class: "d-flex flex-column mb-10 fv-row" }
const _hoisted_49 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_50 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_51 = {
  key: 2,
  class: "col-md-4"
}
const _hoisted_52 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_53 = {
  key: 3,
  class: "col-md-4"
}
const _hoisted_54 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_55 = {
  key: 4,
  class: "col-md-4"
}
const _hoisted_56 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_57 = {
  key: 3,
  class: "row"
}
const _hoisted_58 = { class: "col-md-4" }
const _hoisted_59 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_60 = { class: "col-md-4" }
const _hoisted_61 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_62 = {
  key: 4,
  class: "row"
}
const _hoisted_63 = { key: 0 }
const _hoisted_64 = { key: 1 }
const _hoisted_65 = { key: 2 }
const _hoisted_66 = { key: 3 }
const _hoisted_67 = { key: 4 }
const _hoisted_68 = { key: 5 }
const _hoisted_69 = { key: 6 }
const _hoisted_70 = { key: 7 }
const _hoisted_71 = { key: 8 }
const _hoisted_72 = { key: 9 }
const _hoisted_73 = { key: 10 }
const _hoisted_74 = { key: 11 }
const _hoisted_75 = { key: 12 }
const _hoisted_76 = { key: 13 }
const _hoisted_77 = { key: 14 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Modal - Create Api Key"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Modal header"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin::Modal title"),
            _createElementVNode("span", _hoisted_5, "View " + _toDisplayString(_ctx.payload.variant) + " Device", 1 /* TEXT */),
            _createCommentVNode("end::Modal title"),
            _createCommentVNode("begin::Close"),
            _cache[24] || (_cache[24] = _createElementVNode("div", {
              class: "btn btn-sm btn-icon btn-active-color-primary",
              "data-bs-dismiss": "modal"
            }, [
              _createElementVNode("span", { class: "svg-icon svg-icon-1" }, [
                _createElementVNode("i", {
                  class: "bi bi-x-circle-fill light-text fs-3",
                  style: {"color":"white"}
                })
              ])
            ], -1 /* HOISTED */)),
            _createCommentVNode("end::Close")
          ]),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Form"),
          _createCommentVNode("begin::Modal body"),
          _createElementVNode("div", _hoisted_6, [
            _createCommentVNode("begin::Scroll"),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (
                  _ctx.payload.variant === 'sigfox' ||
                  _ctx.payload.variant === 'lora' ||
                  _ctx.payload.variant === 'nbiot'
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_10, [
                        _createCommentVNode("begin::Label"),
                        _cache[25] || (_cache[25] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Variant", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Select"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          placeholder: "Your API Name",
                          name: "variant_id",
                          modelValue: _ctx.payload.variant,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.variant) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Select")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                (
                  _ctx.payload.variant === 'sigfox' || _ctx.payload.variant === 'lora'
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_12, [
                        _createCommentVNode("begin::Label"),
                        _cache[26] || (_cache[26] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Variant Id", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          placeholder: "Your API Name",
                          name: "variant_id",
                          modelValue: _ctx.payload.variant_id,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.variant_id) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.payload.variant === 'nbiot')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_14, [
                        _createCommentVNode("begin::Label"),
                        _cache[27] || (_cache[27] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Imei", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          placeholder: "Your API Name",
                          name: "variant_id",
                          modelValue: _ctx.payload.variant_id,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.variant_id) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_15, [
                  _createCommentVNode("begin::Input group"),
                  _createElementVNode("div", _hoisted_16, [
                    _createCommentVNode("begin::Label"),
                    _cache[28] || (_cache[28] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Unique Random Number", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createVNode(_component_Field, {
                      readonly: "",
                      type: "text",
                      class: "text-muted form-control text-muted border border-primary form-control-solid",
                      placeholder: "Your API Name",
                      name: "unique_random_number",
                      modelValue: _ctx.payload.unique_random_number,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.unique_random_number) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode("end::Input")
                  ]),
                  _createCommentVNode("end::Input group")
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                (
                  _ctx.payload.variant === 'sigfox' ||
                  _ctx.payload.variant === 'lora' ||
                  _ctx.payload.variant === 'nbiot'
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_19, [
                        _createCommentVNode("begin::Label"),
                        _createElementVNode("label", _hoisted_20, [
                          _cache[29] || (_cache[29] = _createTextVNode(" Serial Number ")),
                          _createElementVNode("i", {
                            class: "fa fa-info-circle info-icon",
                            "aria-hidden": "true",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggle = !_ctx.toggle))
                          })
                        ]),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "serial_number",
                          placeholder: "",
                          modelValue: _ctx.payload.serial_number,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.serial_number) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                (
                  _ctx.payload.variant === 'sigfox' ||
                  _ctx.payload.variant === 'lora' ||
                  _ctx.payload.variant === 'nbiot'
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_22, [
                        _createCommentVNode("begin::Label"),
                        _cache[30] || (_cache[30] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Product Count", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "product_count",
                          placeholder: "",
                          modelValue: _ctx.payload.product_count,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.product_count) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_23, [
                  _createCommentVNode("begin::Input group"),
                  _createElementVNode("div", _hoisted_24, [
                    _createCommentVNode("begin::Label"),
                    _cache[31] || (_cache[31] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Firmware Version", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createVNode(_component_Field, {
                      readonly: "",
                      type: "text",
                      class: "text-muted form-control border border-primary form-control-solid",
                      rows: "3",
                      name: "firmware_version",
                      placeholder: "",
                      modelValue: _ctx.payload.firmware_version,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.firmware_version) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode("end::Input")
                  ]),
                  _createCommentVNode("end::Input group")
                ])
              ]),
              (_ctx.payload.variant === 'lora')
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_27, [
                        _createCommentVNode("begin::Label"),
                        _cache[32] || (_cache[32] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Application Eui", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "application_eui",
                          placeholder: "Application Eui",
                          modelValue: _ctx.payload.application_eui,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.application_eui) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_29, [
                        _createCommentVNode("begin::Label"),
                        _cache[33] || (_cache[33] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Application Key", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "application_key",
                          placeholder: "Application Key",
                          modelValue: _ctx.payload.application_key,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.application_key) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_31, [
                        _createCommentVNode("begin::Label"),
                        _cache[34] || (_cache[34] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Application Session Key", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "application_session_key",
                          placeholder: "Application session key",
                          modelValue: _ctx.payload.application_session_key,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.application_session_key) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.payload.variant === 'nbiot')
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_34, [
                        _createCommentVNode("begin::Label"),
                        _cache[35] || (_cache[35] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "ICCID", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "icc",
                          placeholder: "ICCID",
                          modelValue: _ctx.payload.icc,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.icc) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_36, [
                        _createCommentVNode("begin::Label"),
                        _cache[36] || (_cache[36] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Antenna", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "antenna",
                          placeholder: "Antenna",
                          modelValue: _ctx.payload.antenna,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.antenna) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_38, [
                        _createCommentVNode("begin::Label"),
                        _cache[37] || (_cache[37] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Battery", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "batter",
                          placeholder: "Battery",
                          modelValue: _ctx.payload.batter,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payload.batter) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.payload.variant === 'nbiot')
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    _createElementVNode("div", _hoisted_40, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_41, [
                        _createCommentVNode("begin::Label"),
                        _cache[38] || (_cache[38] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Conformal Coating", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "conformal_coating",
                          placeholder: "Conformal Coting",
                          modelValue: _ctx.payload.conformal_coating,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payload.conformal_coating) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_42, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_43, [
                        _createCommentVNode("begin::Label"),
                        _cache[39] || (_cache[39] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Silicon Between Lid And Housing", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "silicon_between_lid_and_housing",
                          placeholder: "",
                          modelValue: _ctx.payload.silicon_between_lid_and_housing,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.payload.silicon_between_lid_and_housing) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_45, [
                        _createCommentVNode("begin::Label"),
                        _cache[40] || (_cache[40] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Payload Specification Document Version", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "payload_specification_document_version",
                          placeholder: "",
                          modelValue: _ctx.payload.payload_specification_document_version,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.payload.payload_specification_document_version) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_46, [
                (
                  _ctx.payload.variant === 'sigfox' ||
                  _ctx.payload.variant === 'lora' ||
                  _ctx.payload.variant === 'nbiot'
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_48, [
                        _createCommentVNode("begin::Label"),
                        _cache[42] || (_cache[42] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Sensor", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Select"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          name: "sensor",
                          "data-c": "",
                          "otext-mutedntrol": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "Select Sensor Type",
                          class: "form-select border border-primary form-select-solid",
                          as: "select",
                          modelValue: _ctx.payload.sensor,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.payload.sensor) = $event))
                        }, {
                          default: _withCtx(() => _cache[41] || (_cache[41] = [
                            _createElementVNode("option", { value: "" }, "Select Sensor Type", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "JSN 2.0" }, "JSN 2.0", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "JSN 3.0" }, "JSN 3.0", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "TOF" }, "TOF", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Select")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.payload.variant === 'lora')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_50, [
                        _createCommentVNode("begin::Label"),
                        _cache[43] || (_cache[43] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Device Eui", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "device_eui",
                          placeholder: "",
                          modelValue: _ctx.payload.device_eui,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.payload.device_eui) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.payload.variant === 'lora')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_52, [
                        _createCommentVNode("begin::Label"),
                        _cache[44] || (_cache[44] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Network Session Key", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "network_session_key",
                          placeholder: "",
                          modelValue: _ctx.payload.network_session_key,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.payload.network_session_key) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.payload.variant === 'nbiot')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_54, [
                        _createCommentVNode("begin::Label"),
                        _cache[45] || (_cache[45] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Casing Batch Number", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "casing_batch_number",
                          placeholder: "",
                          modelValue: _ctx.payload.casing_batch_number,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.payload.casing_batch_number) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.payload.variant === 'nbiot')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_56, [
                        _createCommentVNode("begin::Label"),
                        _cache[46] || (_cache[46] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Sealant On Tunnel", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "sealant_on_tunnel",
                          placeholder: "",
                          modelValue: _ctx.payload.sealant_on_tunnel,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.payload.sealant_on_tunnel) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              (_ctx.payload.variant === 'nbiot')
                ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                    _createElementVNode("div", _hoisted_58, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_59, [
                        _createCommentVNode("begin::Label"),
                        _cache[47] || (_cache[47] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Gasket", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "gasket",
                          placeholder: "",
                          modelValue: _ctx.payload.gasket,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.payload.gasket) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_60, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_61, [
                        _createCommentVNode("begin::Label"),
                        _cache[48] || (_cache[48] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Screws", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          readonly: "",
                          type: "text",
                          class: "text-muted form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "screws",
                          placeholder: "",
                          modelValue: _ctx.payload.screws,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.payload.screws) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.toggle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                    (_ctx.payload.hardware_reference)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_63, [
                          _cache[49] || (_cache[49] = _createElementVNode("strong", null, "Hardware Reference: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.hardware_reference), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.software_version)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_64, [
                          _cache[50] || (_cache[50] = _createElementVNode("strong", null, "Software Version: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.software_version_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.odm)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_65, [
                          _cache[51] || (_cache[51] = _createElementVNode("strong", null, "ODM: ", -1 /* HOISTED */)),
                          _createTextVNode(_toDisplayString(_ctx.payload.odm_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.assembly_line_batch_number)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_66, [
                          _cache[52] || (_cache[52] = _createElementVNode("strong", null, "Assembly line Batch Number: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.assembly_line_batch_number), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.date)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_67, [
                          _cache[53] || (_cache[53] = _createElementVNode("strong", null, "Date: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.moment(_ctx.payload.date).format("DD MMMM YYYY")), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.product_count_on_assembly_line)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_68, [
                          _cache[54] || (_cache[54] = _createElementVNode("strong", null, "Product Count on Assembly line: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.product_count_on_assembly_line), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.antenna)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_69, [
                          _cache[55] || (_cache[55] = _createElementVNode("strong", null, "Antenna: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.antenna_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.battery)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_70, [
                          _cache[56] || (_cache[56] = _createElementVNode("strong", null, "Battery: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.battery_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.gasket)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_71, [
                          _cache[57] || (_cache[57] = _createElementVNode("strong", null, "Gasket: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.gasket_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.sealant_on_tunnel)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_72, [
                          _cache[58] || (_cache[58] = _createElementVNode("strong", null, "Sealant on the Tunnel: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.sealant_on_tunnel_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.sensor)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_73, [
                          _cache[59] || (_cache[59] = _createElementVNode("strong", null, "Sensor: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.sensor_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.casing_batch_number)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_74, [
                          _cache[60] || (_cache[60] = _createElementVNode("strong", null, "Casing Batch Number: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.casing_batch_number_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.screws)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_75, [
                          _cache[61] || (_cache[61] = _createElementVNode("strong", null, "Screws: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.screws_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.conformal_coating)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_76, [
                          _cache[62] || (_cache[62] = _createElementVNode("strong", null, "Conformal Coating: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.conformal_coating_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.silicon_between_lid_and_housing)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_77, [
                          _cache[63] || (_cache[63] = _createElementVNode("strong", null, "Silicone b/w Lid and Housing on top of Gasket: ", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.payload.silicon_detail), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _createCommentVNode("end::Scroll")
          ]),
          _createCommentVNode("end::Modal body"),
          _createCommentVNode("end::Form")
        ]),
        _createCommentVNode("end::Modal content")
      ]),
      _createCommentVNode("end::Modal dialog")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end::Modal - Create Api Key"),
    _createCommentVNode("End Modal Serial ID Detail ")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}