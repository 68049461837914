import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "general_update_modal",
  ref: "createAPIKeyModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-lg modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = {
  class: "modal-header bg-primary",
  id: "kt_modal_create_api_key_header"
}
const _hoisted_5 = { class: "text-white ms-4 h5 text-uppercase" }
const _hoisted_6 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_7 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_10 = { class: "d-flex flex-column mb-10 fv-row" }
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }
const _hoisted_13 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_14 = { class: "mb-5 fv-row" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = {
  key: 2,
  class: "col-md-4"
}
const _hoisted_18 = { class: "mb-5 fv-row" }
const _hoisted_19 = { class: "fv-plugins-message-container" }
const _hoisted_20 = { class: "fv-help-block" }
const _hoisted_21 = { class: "col-md-4" }
const _hoisted_22 = { class: "mb-5 fv-row" }
const _hoisted_23 = { class: "fv-plugins-message-container" }
const _hoisted_24 = { class: "fv-help-block" }
const _hoisted_25 = { class: "row" }
const _hoisted_26 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_27 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_28 = { class: "fv-plugins-message-container" }
const _hoisted_29 = { class: "fv-help-block" }
const _hoisted_30 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_31 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_32 = { class: "fv-plugins-message-container" }
const _hoisted_33 = { class: "fv-help-block" }
const _hoisted_34 = { class: "col-md-4" }
const _hoisted_35 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_36 = { class: "fv-plugins-message-container" }
const _hoisted_37 = { class: "fv-help-block" }
const _hoisted_38 = {
  key: 0,
  class: "row"
}
const _hoisted_39 = { class: "col-md-4" }
const _hoisted_40 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_41 = { class: "fv-plugins-message-container" }
const _hoisted_42 = { class: "fv-help-block" }
const _hoisted_43 = { class: "col-md-4" }
const _hoisted_44 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_45 = { class: "fv-plugins-message-container" }
const _hoisted_46 = { class: "fv-help-block" }
const _hoisted_47 = { class: "col-md-4" }
const _hoisted_48 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_49 = { class: "fv-plugins-message-container" }
const _hoisted_50 = { class: "fv-help-block" }
const _hoisted_51 = {
  key: 1,
  class: "row"
}
const _hoisted_52 = { class: "col-md-4" }
const _hoisted_53 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_54 = { class: "fv-plugins-message-container" }
const _hoisted_55 = { class: "fv-help-block" }
const _hoisted_56 = { class: "col-md-4" }
const _hoisted_57 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_58 = { class: "fv-plugins-message-container" }
const _hoisted_59 = { class: "fv-help-block" }
const _hoisted_60 = { class: "col-md-4" }
const _hoisted_61 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_62 = { class: "fv-plugins-message-container" }
const _hoisted_63 = { class: "fv-help-block" }
const _hoisted_64 = {
  key: 2,
  class: "row"
}
const _hoisted_65 = { class: "col-md-4" }
const _hoisted_66 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_67 = { class: "fv-plugins-message-container" }
const _hoisted_68 = { class: "fv-help-block" }
const _hoisted_69 = { class: "col-md-4" }
const _hoisted_70 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_71 = { class: "fv-plugins-message-container" }
const _hoisted_72 = { class: "fv-help-block" }
const _hoisted_73 = { class: "col-md-4" }
const _hoisted_74 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_75 = { class: "fv-plugins-message-container" }
const _hoisted_76 = { class: "fv-help-block" }
const _hoisted_77 = { class: "row" }
const _hoisted_78 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_79 = { class: "d-flex flex-column mb-10 fv-row" }
const _hoisted_80 = { class: "fv-plugins-message-container" }
const _hoisted_81 = { class: "fv-help-block" }
const _hoisted_82 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_83 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_84 = { class: "fv-plugins-message-container" }
const _hoisted_85 = { class: "fv-help-block" }
const _hoisted_86 = {
  key: 2,
  class: "col-md-4"
}
const _hoisted_87 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_88 = { class: "fv-plugins-message-container" }
const _hoisted_89 = { class: "fv-help-block" }
const _hoisted_90 = {
  key: 3,
  class: "col-md-4"
}
const _hoisted_91 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_92 = { class: "fv-plugins-message-container" }
const _hoisted_93 = { class: "fv-help-block" }
const _hoisted_94 = {
  key: 4,
  class: "col-md-4"
}
const _hoisted_95 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_96 = { class: "fv-plugins-message-container" }
const _hoisted_97 = { class: "fv-help-block" }
const _hoisted_98 = {
  key: 3,
  class: "row"
}
const _hoisted_99 = { class: "col-md-4" }
const _hoisted_100 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_101 = { class: "fv-plugins-message-container" }
const _hoisted_102 = { class: "fv-help-block" }
const _hoisted_103 = { class: "col-md-4" }
const _hoisted_104 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_105 = { class: "fv-plugins-message-container" }
const _hoisted_106 = { class: "fv-help-block" }
const _hoisted_107 = { class: "modal-footer flex-center" }
const _hoisted_108 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_create_api_key_submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Modal - Create Api Key"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Modal header"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin::Modal title"),
            _createElementVNode("span", _hoisted_5, "Update " + _toDisplayString(_ctx.payload.variant) + " device ", 1 /* TEXT */),
            _createCommentVNode("end::Modal title"),
            _createCommentVNode("begin::Close"),
            _cache[23] || (_cache[23] = _createElementVNode("div", {
              class: "btn btn-sm btn-icon btn-active-color-primary",
              "data-bs-dismiss": "modal",
              id: "editdevicedata"
            }, [
              _createElementVNode("span", { class: "svg-icon svg-icon-1" }, [
                _createElementVNode("i", {
                  class: "bi bi-x-circle-fill light-text fs-3",
                  style: {"color":"white"}
                })
              ])
            ], -1 /* HOISTED */)),
            _createCommentVNode("end::Close")
          ]),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Form"),
          _createVNode(_component_Form, {
            id: "kt_modal_create_api_key_form",
            class: "form",
            onSubmit: _ctx.submit,
            "validation-schema": 
            _ctx.payload.variant == 'sigfox'
              ? _ctx.sigfoxvalidationSchema
              : _ctx.payload.variant == 'lora'
              ? _ctx.loravalidationSchema
              : _ctx.payload.variant == 'nbiot'
              ? _ctx.nbiotvalidationSchema
              : null
          
          }, {
            default: _withCtx(() => [
              _createCommentVNode("begin::Modal body"),
              _createElementVNode("div", _hoisted_6, [
                _createCommentVNode("begin::Scroll"),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (
                    _ctx.payload.variant === 'sigfox' ||
                    _ctx.payload.variant === 'lora' ||
                    _ctx.payload.variant === 'nbiot'
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_10, [
                            _createCommentVNode("begin::Label"),
                            _cache[25] || (_cache[25] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Variant", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Select"),
                            _createVNode(_component_Field, {
                              name: "variant",
                              "data-control": "select2",
                              "data-hide-search": "true",
                              "data-placeholder": "Select a Variant...",
                              class: "form-select border border-primary form-select-solid",
                              as: "select",
                              modelValue: _ctx.payload.variant,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.variant) = $event))
                            }, {
                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                _createElementVNode("option", { value: "" }, "Select a Variant...", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "lora" }, "Lora", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "sigfox" }, "Sigfox", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "nbiot" }, "nbIot", -1 /* HOISTED */)
                              ])),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_ErrorMessage, { name: "variant" })
                              ])
                            ]),
                            _createCommentVNode("end::Select")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (
                    _ctx.payload.variant === 'sigfox' || _ctx.payload.variant === 'lora'
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_14, [
                            _createCommentVNode("begin::Label"),
                            _cache[26] || (_cache[26] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Variant Id", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              placeholder: "Your API Name",
                              name: "variant_id",
                              modelValue: _ctx.payload.variant_id,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.variant_id) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createVNode(_component_ErrorMessage, { name: "variant_id" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.variant === 'nbiot')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_18, [
                            _createCommentVNode("begin::Label"),
                            _cache[27] || (_cache[27] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Imei", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              placeholder: "Your API Name",
                              name: "variant_id",
                              modelValue: _ctx.payload.variant_id,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.variant_id) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _createVNode(_component_ErrorMessage, { name: "variant_id" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_21, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_22, [
                        _createCommentVNode("begin::Label"),
                        _cache[28] || (_cache[28] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Unique Random Number", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control border border-primary form-control-solid",
                          placeholder: "Enter unique random number",
                          name: "unique_random_number",
                          modelValue: _ctx.payload.unique_random_number,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.unique_random_number) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createVNode(_component_ErrorMessage, { name: "unique_random_number" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    (
                    _ctx.payload.variant === 'sigfox' ||
                    _ctx.payload.variant === 'lora' ||
                    _ctx.payload.variant === 'nbiot'
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_27, [
                            _createCommentVNode("begin::Label"),
                            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Serial Number", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "serial_number",
                              placeholder: "Enter Serial number",
                              modelValue: _ctx.payload.serial_number,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.serial_number) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("div", _hoisted_29, [
                                _createVNode(_component_ErrorMessage, { name: "serial_number" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (
                    _ctx.payload.variant === 'sigfox' ||
                    _ctx.payload.variant === 'lora' ||
                    _ctx.payload.variant === 'nbiot'
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_31, [
                            _createCommentVNode("begin::Label"),
                            _cache[30] || (_cache[30] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Product Count", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "product_count",
                              placeholder: "Enter Product Count",
                              modelValue: _ctx.payload.product_count,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.product_count) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_32, [
                              _createElementVNode("div", _hoisted_33, [
                                _createVNode(_component_ErrorMessage, { name: "product_count" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_34, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_35, [
                        _createCommentVNode("begin::Label"),
                        _cache[31] || (_cache[31] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Firmware Version", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control border border-primary form-control-solid",
                          rows: "3",
                          name: "firmware_version",
                          placeholder: "Enter firmware version",
                          modelValue: _ctx.payload.firmware_version,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.firmware_version) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, [
                            _createVNode(_component_ErrorMessage, { name: "firmware_version" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ]),
                  (_ctx.payload.variant === 'lora')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                        _createElementVNode("div", _hoisted_39, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_40, [
                            _createCommentVNode("begin::Label"),
                            _cache[32] || (_cache[32] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Application Eui", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "application_eui",
                              placeholder: "Application Eui",
                              modelValue: _ctx.payload.application_eui,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.application_eui) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_41, [
                              _createElementVNode("div", _hoisted_42, [
                                _createVNode(_component_ErrorMessage, { name: "application_eui" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]),
                        _createElementVNode("div", _hoisted_43, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_44, [
                            _createCommentVNode("begin::Label"),
                            _cache[33] || (_cache[33] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Application Key", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "application_key",
                              placeholder: "Application Key",
                              modelValue: _ctx.payload.application_key,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.payload.application_key) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_45, [
                              _createElementVNode("div", _hoisted_46, [
                                _createVNode(_component_ErrorMessage, { name: "application_key" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]),
                        _createElementVNode("div", _hoisted_47, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_48, [
                            _createCommentVNode("begin::Label"),
                            _cache[34] || (_cache[34] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Application Session Key", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "application_session_key",
                              placeholder: "Application session key",
                              modelValue: _ctx.payload.application_session_key,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.payload.application_session_key) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_49, [
                              _createElementVNode("div", _hoisted_50, [
                                _createVNode(_component_ErrorMessage, { name: "application_session_key" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ])
                      ]))
                    : _createCommentVNode("v-if", true),
                  (_ctx.payload.variant === 'nbiot')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                        _createElementVNode("div", _hoisted_52, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_53, [
                            _createCommentVNode("begin::Label"),
                            _cache[35] || (_cache[35] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "ICCID", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "icc",
                              placeholder: " Enter ICCID",
                              modelValue: _ctx.payload.icc,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.payload.icc) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_54, [
                              _createElementVNode("div", _hoisted_55, [
                                _createVNode(_component_ErrorMessage, { name: "icc" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]),
                        _createElementVNode("div", _hoisted_56, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_57, [
                            _createCommentVNode("begin::Label"),
                            _cache[36] || (_cache[36] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Antenna", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "antenna",
                              placeholder: "Enter Antenna",
                              modelValue: _ctx.payload.antenna,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.antenna) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_58, [
                              _createElementVNode("div", _hoisted_59, [
                                _createVNode(_component_ErrorMessage, { name: "antenna" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]),
                        _createElementVNode("div", _hoisted_60, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_61, [
                            _createCommentVNode("begin::Label"),
                            _cache[37] || (_cache[37] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Battery", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "batter",
                              placeholder: "Enter Battery",
                              modelValue: _ctx.payload.batter,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.batter) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_62, [
                              _createElementVNode("div", _hoisted_63, [
                                _createVNode(_component_ErrorMessage, { name: "batter" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ])
                      ]))
                    : _createCommentVNode("v-if", true),
                  (_ctx.payload.variant === 'nbiot')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                        _createElementVNode("div", _hoisted_65, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_66, [
                            _createCommentVNode("begin::Label"),
                            _cache[38] || (_cache[38] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Conformal Coating", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "conformal_coating",
                              placeholder: "Enter Conformal Coting",
                              modelValue: _ctx.payload.conformal_coating,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.payload.conformal_coating) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_67, [
                              _createElementVNode("div", _hoisted_68, [
                                _createVNode(_component_ErrorMessage, { name: "conformal_coating" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]),
                        _createElementVNode("div", _hoisted_69, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_70, [
                            _createCommentVNode("begin::Label"),
                            _cache[39] || (_cache[39] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Silicon Between Lid And Housing", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "silicon_between_lid_and_housing",
                              placeholder: "Enter Silicon, lid and housing",
                              modelValue: _ctx.payload.silicon_between_lid_and_housing,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payload.silicon_between_lid_and_housing) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_71, [
                              _createElementVNode("div", _hoisted_72, [
                                _createVNode(_component_ErrorMessage, { name: "silicon_between_lid_and_housing" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]),
                        _createElementVNode("div", _hoisted_73, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_74, [
                            _createCommentVNode("begin::Label"),
                            _cache[40] || (_cache[40] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Payload Specification Document Version", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "payload_specification_document_version",
                              placeholder: "Enter Payload document version",
                              modelValue: _ctx.payload.payload_specification_document_version,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.payload.payload_specification_document_version) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_75, [
                              _createElementVNode("div", _hoisted_76, [
                                _createVNode(_component_ErrorMessage, { name: "payload_specification_document_version" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ])
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_77, [
                    (
                    _ctx.payload.variant === 'sigfox' ||
                    _ctx.payload.variant === 'lora' ||
                    _ctx.payload.variant === 'nbiot'
                  )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_79, [
                            _createCommentVNode("begin::Label"),
                            _cache[42] || (_cache[42] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Sensor", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Select"),
                            _createVNode(_component_Field, {
                              name: "sensor",
                              "data-control": "select2",
                              "data-hide-search": "true",
                              "data-placeholder": "Select Sensor Type",
                              class: "form-select border border-primary form-select-solid",
                              as: "select",
                              modelValue: _ctx.payload.sensor,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.payload.sensor) = $event))
                            }, {
                              default: _withCtx(() => _cache[41] || (_cache[41] = [
                                _createElementVNode("option", { value: "" }, "Select Sensor Type", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "JSN 2.0" }, "JSN 2.0", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "JSN 3.0" }, "JSN 3.0", -1 /* HOISTED */),
                                _createElementVNode("option", { value: "TOF" }, "TOF", -1 /* HOISTED */)
                              ])),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_80, [
                              _createElementVNode("div", _hoisted_81, [
                                _createVNode(_component_ErrorMessage, { name: "sensor" })
                              ])
                            ]),
                            _createCommentVNode("end::Select")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.variant === 'lora')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_83, [
                            _createCommentVNode("begin::Label"),
                            _cache[43] || (_cache[43] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Device Eui", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "device_eui",
                              placeholder: "Enter device eui",
                              modelValue: _ctx.payload.device_eui,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.payload.device_eui) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_84, [
                              _createElementVNode("div", _hoisted_85, [
                                _createVNode(_component_ErrorMessage, { name: "device_eui" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.variant === 'lora')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_87, [
                            _createCommentVNode("begin::Label"),
                            _cache[44] || (_cache[44] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Network Session Key", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "network_session_key",
                              placeholder: "Enter session key",
                              modelValue: _ctx.payload.network_session_key,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.payload.network_session_key) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_88, [
                              _createElementVNode("div", _hoisted_89, [
                                _createVNode(_component_ErrorMessage, { name: "network_session_key" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.variant === 'nbiot')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_91, [
                            _createCommentVNode("begin::Label"),
                            _cache[45] || (_cache[45] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Casing Batch Number", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "casing_batch_number",
                              placeholder: "Enter casing batch number",
                              modelValue: _ctx.payload.casing_batch_number,
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.payload.casing_batch_number) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_92, [
                              _createElementVNode("div", _hoisted_93, [
                                _createVNode(_component_ErrorMessage, { name: "casing_batch_number" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.payload.variant === 'nbiot')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_95, [
                            _createCommentVNode("begin::Label"),
                            _cache[46] || (_cache[46] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Sealant On Tunnel", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "sealant_on_tunnel",
                              placeholder: "Enter Sealant on tunnel",
                              modelValue: _ctx.payload.sealant_on_tunnel,
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.payload.sealant_on_tunnel) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_96, [
                              _createElementVNode("div", _hoisted_97, [
                                _createVNode(_component_ErrorMessage, { name: "sealant_on_tunnel" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  (_ctx.payload.variant === 'nbiot')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_98, [
                        _createElementVNode("div", _hoisted_99, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_100, [
                            _createCommentVNode("begin::Label"),
                            _cache[47] || (_cache[47] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Gasket", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "gasket",
                              placeholder: "Enter gasket",
                              modelValue: _ctx.payload.gasket,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.payload.gasket) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_101, [
                              _createElementVNode("div", _hoisted_102, [
                                _createVNode(_component_ErrorMessage, { name: "gasket" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ]),
                        _createElementVNode("div", _hoisted_103, [
                          _createCommentVNode("begin::Input group"),
                          _createElementVNode("div", _hoisted_104, [
                            _createCommentVNode("begin::Label"),
                            _cache[48] || (_cache[48] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Screws", -1 /* HOISTED */)),
                            _createCommentVNode("end::Label"),
                            _createCommentVNode("begin::Input"),
                            _createVNode(_component_Field, {
                              type: "text",
                              class: "form-control border border-primary form-control-solid",
                              rows: "3",
                              name: "screws",
                              placeholder: "Enter Screws",
                              modelValue: _ctx.payload.screws,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.payload.screws) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_105, [
                              _createElementVNode("div", _hoisted_106, [
                                _createVNode(_component_ErrorMessage, { name: "screws" })
                              ])
                            ]),
                            _createCommentVNode("end::Input")
                          ]),
                          _createCommentVNode("end::Input group")
                        ])
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _createCommentVNode("end::Scroll")
              ]),
              _createCommentVNode("end::Modal body"),
              _createCommentVNode("begin::Modal footer"),
              _createElementVNode("div", _hoisted_107, [
                _createCommentVNode("begin::Button"),
                _cache[50] || (_cache[50] = _createElementVNode("button", {
                  type: "reset",
                  id: "kt_modal_create_api_key_cancel",
                  class: "btn btn-white me-3"
                }, " Clear ", -1 /* HOISTED */)),
                _createCommentVNode("end::Button"),
                _createCommentVNode("begin::Button"),
                _createElementVNode("button", _hoisted_108, _cache[49] || (_cache[49] = [
                  _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
                  _createElementVNode("span", { class: "indicator-progress" }, [
                    _createTextVNode(" Please wait... "),
                    _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                  ], -1 /* HOISTED */)
                ]), 512 /* NEED_PATCH */),
                _createCommentVNode("end::Button")
              ]),
              _createCommentVNode("end::Modal footer")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
          _createCommentVNode("end::Form")
        ]),
        _createCommentVNode("end::Modal content")
      ]),
      _createCommentVNode("end::Modal dialog")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end::Modal - Create Api Key")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}