import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "card border-0",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-2" }
const _hoisted_6 = { class: "border border-primary bg-light rounded-2" }
const _hoisted_7 = { class: "border border-primary rounded-2 ms-5" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "card-toolbar" }
const _hoisted_10 = { class: "d-flex me-4 justify-content-end" }
const _hoisted_11 = {
  key: 1,
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_12 = {
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_new_device"
}
const _hoisted_13 = { class: "svg-icon svg-icon-2" }
const _hoisted_14 = {
  key: 2,
  class: "d-flex ms-4 justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_15 = { class: "btn btn-success" }
const _hoisted_16 = { class: "svg-icon svg-icon-2" }
const _hoisted_17 = { class: "card-body pt-0" }
const _hoisted_18 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_19 = ["value", "onChange", "checked"]
const _hoisted_20 = { style: {"padding-left":"3.5rem"} }
const _hoisted_21 = { style: {"padding-left":"3.5rem"} }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_ViewDeviceModal = _resolveComponent("ViewDeviceModal")!
  const _component_KTNewDeviceModal = _resolveComponent("KTNewDeviceModal")!
  const _component_DeviceUpdatemodal = _resolveComponent("DeviceUpdatemodal")!
  const _component_DeleteDevicemodal = _resolveComponent("DeleteDevicemodal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "text-primary ms-10 mb-0" }, "Devices", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
            ]),
            _createElementVNode("span", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                "data-kt-subscription-table-filter": "search",
                class: "form-control form-control-solid w-250px ps-14",
                placeholder: "Search Device"
              }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                [_vModelText, _ctx.search]
              ])
            ]),
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("select", {
                class: "form-select form-select-solid",
                "data-control": "select2",
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
                "data-dropdown-parent": "#kt_modal_1",
                "data-placeholder": "Select an option",
                "data-allow-clear": "true"
              }, [
                _cache[5] || (_cache[5] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: customer.id
                  }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_8))
                }), 128 /* KEYED_FRAGMENT */))
              ], 32 /* NEED_HYDRATION */)
            ])
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createElementVNode("div", _hoisted_9, [
          _createCommentVNode("begin::Toolbar"),
          _createCommentVNode(" Delete button start "),
          (_ctx.permission.includes('bulk-delete'))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["d-flex me-4 justify-content-end", _ctx.checkedValue.length > 0 ? 'd-block' : 'd-none']),
                "data-kt-subscription-table-toolbar": "base"
              }, [
                _createElementVNode("button", {
                  class: "btn btn-danger",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.deleteDevices && _ctx.deleteDevices(...args)))
                }, [
                  _createCommentVNode(" data-bs-target=\"#kt_modal_new_device\" "),
                  _cache[6] || (_cache[6] = _createTextVNode(" Delete Device "))
                ]),
                _createCommentVNode("end::Add subscription")
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode(" Delete button end "),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.excelExport && _ctx.excelExport(...args)))
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("span", { class: "fontsize" }, "Export Data ", -1 /* HOISTED */)
            ]))
          ]),
          (_ctx.permission.includes('a-device'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("button", _hoisted_12, [
                  _createCommentVNode(" data-bs-target=\"#kt_modal_new_device\" "),
                  _createElementVNode("span", _hoisted_13, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "fontsize" }, "Add Device ", -1 /* HOISTED */))
                ]),
                _createCommentVNode("end::Add subscription")
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.permission.includes('add-device-by-csv'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_router_link, { to: "/provisioning/devices/add_repo" }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", _hoisted_15, [
                      _createCommentVNode(" data-bs-target=\"#kt_modal_new_device\" "),
                      _createElementVNode("span", _hoisted_16, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
                      ]),
                      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "fontsize" }, " Add Device By CSV", -1 /* HOISTED */))
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode("end::Add subscription")
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("end::Toolbar"),
          _createCommentVNode("begin::Group actions"),
          _cache[10] || (_cache[10] = _createElementVNode("div", {
            class: "d-flex justify-content-end align-items-center d-none",
            "data-kt-subscription-table-toolbar": "selected"
          }, [
            _createElementVNode("div", { class: "fw-bolder me-5" }, [
              _createElementVNode("span", {
                class: "me-2",
                "data-kt-subscription-table-select": "selected_count"
              }),
              _createTextVNode(" Selected ")
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-danger",
              "data-kt-subscription-table-select": "delete_selected"
            }, " Delete Selected ")
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Group actions")
        ]),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.data,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          checked: _ctx.checked,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange,
          onSelectAll: _ctx.selectAll
        }, {
          "cell-checkbox": _withCtx(({ row: device }) => [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("input", {
                class: "form-check-input border border-primary",
                type: "checkbox",
                value: device.variant_id,
                onChange: ($event: any) => (_ctx.selectDevice(device)),
                checked: _ctx.isChecked(device.variant_id)
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_19)
            ])
          ]),
          "cell-variant_id": _withCtx(({ row: device }) => [
            _createElementVNode("div", null, _toDisplayString(device.variant_id), 1 /* TEXT */)
          ]),
          "cell-variant": _withCtx(({ row: device }) => [
            _createElementVNode("div", null, _toDisplayString(device.variant), 1 /* TEXT */)
          ]),
          "cell-name": _withCtx(({ row: device }) => [
            _createTextVNode(_toDisplayString(device.name ? device.name + " (ID: " + device.id + ")" : "N/A"), 1 /* TEXT */)
          ]),
          "cell-api_doc_version": _withCtx(({ row: device }) => [
            _createElementVNode("span", _hoisted_20, _toDisplayString(device.api_doc_version), 1 /* TEXT */)
          ]),
          "cell-firmware_version": _withCtx(({ row: device }) => [
            _createElementVNode("span", _hoisted_21, _toDisplayString(device.firmware_version), 1 /* TEXT */)
          ]),
          "cell-action": _withCtx(({ row: device }) => [
            _createTextVNode(_toDisplayString(device.action) + " ", 1 /* TEXT */),
            _createElementVNode("a", {
              href: "#",
              class: "text-gray-600 text-hover-primary mb-1",
              title: "View",
              "data-bs-toggle": "modal",
              "data-bs-target": "#general_view_modal",
              onClick: ($event: any) => (_ctx.editdeviceData(device))
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("div", {
                class: _normalizeClass(`badge badge-light-success`)
              }, [
                _createElementVNode("i", { class: "far fa-eye text-primary" })
              ], -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_22),
            (_ctx.permission.includes('edit-device'))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  class: "text-gray-600 text-hover-primary mx-1",
                  title: "Edit",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#general_update_modal",
                  onClick: ($event: any) => (_ctx.editdeviceData(device))
                }, _cache[12] || (_cache[12] = [
                  _createElementVNode("div", {
                    class: _normalizeClass(`badge badge-light-success`)
                  }, [
                    _createElementVNode("i", { class: "fas fa-edit text-success" })
                  ], -1 /* HOISTED */)
                ]), 8 /* PROPS */, _hoisted_23))
              : _createCommentVNode("v-if", true),
            (_ctx.permission.includes('delete-device'))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: "#",
                  class: "text-gray-600 text-hover-primary mb-1",
                  title: "Delete",
                  onClick: ($event: any) => (_ctx.deleteDevice(device.variant_id))
                }, _cache[13] || (_cache[13] = [
                  _createElementVNode("div", {
                    class: _normalizeClass(`badge badge-light-success`)
                  }, [
                    _createElementVNode("i", { class: "fas fa-trash text-danger" })
                  ], -1 /* HOISTED */)
                ]), 8 /* PROPS */, _hoisted_24))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "checked", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange", "onSelectAll"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createCommentVNode("end::Card"),
    _createVNode(_component_ViewDeviceModal, { varientData: _ctx.deviceData }, null, 8 /* PROPS */, ["varientData"]),
    _createVNode(_component_KTNewDeviceModal),
    _createVNode(_component_DeviceUpdatemodal, { varientData: _ctx.deviceData }, null, 8 /* PROPS */, ["varientData"]),
    _createVNode(_component_DeleteDevicemodal, {
      onPra: _ctx.parentfunc,
      variantId: _ctx.d_varinat
    }, null, 8 /* PROPS */, ["onPra", "variantId"])
  ], 64 /* STABLE_FRAGMENT */))
}